import React from "react"
import Seo from "../components/SEO"
import useSiteMetadata from "../hooks/useSiteMetadata"

const Terms = () => {
  return (
    <section className="py-[4.375rem]">
      <div className="container">
        <div className="title-sec m-auto w-full">
          <h1 className="lg:text-[2.5rem] md:text-[2.3rem] text-[2rem] pb-4 font-blancotrialbold lg:leading-[3.75rem] md:leading-[3rem] leading-[2.75rem] text-center">
            Terms & Conditions
            <span className="bg-orangecol w-2 h-2 rounded-full inline-block ml-1"></span>
          </h1>
          <h3 className="text-[1.5rem] pb-1 font-blancotrialbold leading-[2.5rem] text-left">
            General Work Terms and Client Responsibilities
          </h3>
          <p className="text-base leading-[1.7rem] text-left text-grey pb-6">
            All site content (text and multimedia) will be the sole
            responsibility of the client to provide to Techuz. Such should be
            provided prior to commencing the work.
          </p>
          <p className="text-base leading-[1.7rem] text-left text-grey pb-6">
            The Contract does not hold Techuz responsible for any data entry,
            web hosting or custom artwork/graphics related work/tasks unless
            otherwise specifically mentioned, paid for and agreed to by both the
            parties towards such. Any artwork, images, or text supplied and/or
            designed by Techuz on behalf of the client, will remain the property
            of Techuz and/or it's suppliers unless otherwise agreed.
          </p>
          <h3 className="text-[1.5rem] pb-4 font-blancotrialbold leading-[2.5rem] text-left">
            Personal information we collect:
          </h3>
          <p className="text-base leading-[1.7rem] text-left text-grey pb-6">
            Client is solely responsible to take proper back-up of all content
            on their site prior to letting Techuz undertake the required course
            of action towards meeting the contract. Any loss or damage to
            existing data shall not be a responsibility of Techuz under any
            circumstances.
          </p>
          <p className="text-base leading-[1.7rem] text-left text-grey pb-6">
            While Techuz will do its best to achieve all deliveries within the
            estimated time, there may, at times, be the need to extend or adjust
            time in cases of any unavoidable and non-forecasted situations like
            those of deployment issues, dependencies, 3rd-Party support,
            development bottle-necks, resource unavailability due to emergency,
            communication delays and the like.
          </p>
          <p className="text-base leading-[1.7rem] text-left text-grey pb-6">
            The Client retains the copyright to data, files and graphic logos
            provided by the Client and grants Techuz the rights to publish and
            use such material. The Client must obtain permission and rights to
            use any information or files that are copyrighted by a third party.
            The Client is further responsible for granting Techuz permission and
            rights for use of the same and agrees to indemnify and hold harmless
            Techuz from any and all claims resulting from the Client's
            negligence or inability to obtain proper copyright permissions. A
            contract for Web site design and/or placement shall be regarded as a
            guarantee by the Client to Techuz that all such permissions and
            authorities have been obtained. Evidence of permissions and
            authorities may be requested.
          </p>
          <p className="text-base leading-[1.7rem] text-left text-grey pb-6">
            Techuz will provide the Client an opportunity to review the
            appearance and content of the Web site during the design and once
            they are completed. Techuz shall wait for a period of 7 days to hear
            any feedback on such shared work/outputs from the client. In the
            event of client not replying within this period, such material will
            deemed to have been automatically accepted and approved by the
            Client.
          </p>
          <p className="text-base leading-[1.7rem] text-left text-grey pb-6">
            Techuz will not accept responsibility for any alterations caused by
            the Client or a third party occurring to the Client's pages/website
            once installed/deployed. Such alterations include, but are not
            limited to additions, modifications or deletions. Techuz may require
            a one-off Web Development charge before resolving any issues that
            may arise.
          </p>
          <h3 className="text-[1.5rem] pb-4 font-blancotrialbold leading-[2.5rem] text-left">
            Payments
          </h3>
          <p className="text-base leading-[1.7rem] text-left text-grey pb-6">
            Techuz accepts payments by cheque, Cash or Bank Transfers (although
            we reserve the right to decline payment in any of these forms
            without notice). Without limitation, Techuz reserves the right to
            withdraw any payment methods at any time and to vary its prices
            without prior notice.
          </p>
          <h3 className="text-[1.5rem] pb-4 font-blancotrialbold leading-[2.5rem] text-left">
            Web Servers:
          </h3>
          <p className="text-base leading-[1.7rem] text-left text-grey pb-6">
            A cancellation fee may be charged if the Customer cancels the
            Service prior to completion. The fee will be equal to the amount of
            work completed at the point of cancellation.
          </p>
          <p className="text-base leading-[1.7rem] text-left text-grey pb-6">
            A non-payment of cancellation fee and/or over-due amount will result
            in legal action upon necessity.
          </p>
          <h3 className="text-[1.5rem] pb-4 font-blancotrialbold leading-[2.5rem] text-left">
            Support and 3rd-Party
          </h3>
          <p className="text-base leading-[1.7rem] text-left text-grey pb-6">
            As the site launches, we offer free support for the first month.
            After one month of free service, we charge according to our various
            price packages best-suited to client's requirement. We also provide
            attractive discounts if the client chooses a higher price package.
            The scope of support only includes any bug fixing and email support
            and excludes any issues related to the site architecture, rule
            changes and add-ons/enhancements.
          </p>
          <p className="text-base leading-[1.7rem] text-left text-grey pb-6">
            Any 3rd-Party support, product and/or service being used/ integrated
            into the site which requires licensing, payment, copyright, etc.
            shall be the sole responsibility and liability of and be provided by
            the client or will be procured by Techuz on behalf of the client on
            pre-payment for the cost of such procurement. The fee charged by
            Techuz is exclusive of out-of-pocket expenses and expense claims
            filed by third party products/services involved.
          </p>
          <p className="text-base leading-[1.7rem] text-left text-grey pb-6">
            Any upgrade in the 3rd-Party product/service being used in the
            project shall not be part of scope at Techuz. Such shall be
            addressed per feasibility and revision of price and time may be
            called for by Techuz.
          </p>
          <p className="text-base leading-[1.7rem] text-left text-grey pb-6">
            No guarantees or warranties shall be provided by Techuz for the
            accuracy or performance of such 3rd-Party product/service.
          </p>
          <h3 className="text-[1.5rem] pb-4 font-blancotrialbold leading-[2.5rem] text-left">
            Re-work, Enhancements/Add-ons and Billing
          </h3>
          <p className="text-base leading-[1.7rem] text-left text-grey pb-6">
            Any additional features not envisaged in the scope of work would be
            entertained through a Change Management process and be additional
            billed. Scope Creeps after wireframe sign off would be billed as
            additional and time lines and cost for delivery would increase.
          </p>
          <p className="text-base leading-[1.7rem] text-left text-grey pb-6">
            Most tweaks such as minor changes/re-work are normally just
            completed, however, if we feel this is being abused, we will build a
            list of works found being the grounds of abuse and bill such
            additionally per the total time efforts involved and undertaken to
            achieve the work/tasks at business rates ranging from US $ 15 to 20
            per hour.
          </p>
          <p className="text-base leading-[1.7rem] text-left text-grey pb-6">
            Whilst we try our best to cover most changes within the budget of
            the site, some changes are classified as enhancements/add-ons to the
            system and become chargeable; we will advise you before we start
            work of any such items.
          </p>
          <p className="text-base leading-[1.7rem] text-left text-grey pb-6">
            Any re-work, change or tweak request by the client post approval
            and/or furthering to the subsequent stage of project process shall
            be treated as add-on work and be additionally billed.
          </p>
          <p className="text-base leading-[1.7rem] text-left text-grey pb-6">
            The client must pay the fee charged by Techuz without any
            deductions, discounts or debt settlement by the agreed due dates.
          </p>
          <h3 className="text-[1.5rem] pb-4 font-blancotrialbold leading-[2.5rem] text-left">
            Limitations of Liability
          </h3>
          <p className="text-base leading-[1.7rem] text-left text-grey pb-6">
            Techuz will use reasonable skill and care in providing the Service.
            However, we make no representation and exclude any warranty, express
            or implied, as to the availability, quality, accuracy, timeliness,
            completeness, performance or fitness of the Service.
          </p>
          <p className="text-base leading-[1.7rem] text-left text-grey pb-6">
            Techuz hereby excludes itself, its Employees and or Agents from: all
            and any liability for loss or damage caused by any inaccuracy;
            omission; delay or error, whether the result of negligence or other
            cause in the production of the website; All and any liability for
            loss or damage to clients' artwork/photos, data/content supplied for
            the site. This is whether the loss or damage results from negligence
            or otherwise.
          </p>
          <p className="text-base leading-[1.7rem] text-left text-grey pb-6">
            Except in the event of death or personal injury caused by our
            negligent acts or omissions, we shall not be liable in any way for
            any damages arising in contract, tort or otherwise in respect of
            loss or damage arising out of or in connection with this Agreement
            or operation of the Service. In no event will we be liable for any
            direct, indirect or consequential damages in contract or tort,
            including loss of profit, loss or damage to property or relating to
            claims made by third parties.
          </p>
          <p className="text-base leading-[1.7rem] text-left text-grey pb-6">
            Techuz cannot make guarantees of service on behalf of third party
            organizations and will not be held liable for the failure in any
            service provided by third parties.
          </p>
          <h3 className="text-[1.5rem] pb-4 font-blancotrialbold leading-[2.5rem] text-left">
            Approvals and Delivery
          </h3>
          <p className="text-base leading-[1.7rem] text-left text-grey pb-6">
            The project will involve various stages and the work for the next
            stage will only start after receiving the sign off on and relevant
            payments for the previous stage as agreed.
          </p>
          <p className="text-base leading-[1.7rem] text-left text-grey pb-6">
            All code and material developed will be transferred post completion
            of project and after sign offs. The code ownership will reside with
            the client after final payments.
          </p>
          <p className="text-base leading-[1.7rem] text-left text-grey pb-6">
            On completion of the Service (Website design and/or website
            development), the website will be uploaded to the Customer area of
            Techuz server for approval. Upon approval by the Client, the website
            will be uploaded to the destination server where the site shall
            stay. Techuz reserves the right to delay uploading of the website
            until full payment has been received.
          </p>
          <p className="text-base leading-[1.7rem] text-left text-grey pb-6">
            Techuz holds the Right to publish and use the completed work and/or
            even the deployed final produce/website for references to other
            potential clients. In circumstances such is required to be withheld,
            client shall notify Techuz well in advance and request prior and
            proper approvals towards the same.
          </p>
          <h3 className="text-[1.5rem] pb-4 font-blancotrialbold leading-[2.5rem] text-left">
            Timescale
          </h3>
          <p className="text-base leading-[1.7rem] text-left text-grey pb-6">
            Techuz will aim to complete all services within the agreed
            timescale. The timescale will commence on receipt of both the agreed
            % deposit (ranging from 30% to 50% of the project price as mutually
            discussed and agreed prior to contract finalization) as acceptance
            and all website content from the Customer.
          </p>
          <p className="text-base leading-[1.7rem] text-left text-grey pb-6">
            Techuz may need to extend any timescales due to circumstances beyond
            its control.
          </p>
          <h3 className="text-[1.5rem] pb-4 font-blancotrialbold leading-[2.5rem] text-left">
            Jurisdiction
          </h3>
          <p className="text-base leading-[1.7rem] text-left text-grey pb-6">
            These terms shall be governed by and interpreted in accordance with
            Indian Law. The parties irrevocably agree that the courts of India
            shall have exclusive jurisdiction to settle any dispute which may
            arise out of, under, or in connection with these Terms and
            Conditions. The placing of an order will confirm acceptance of these
            conditions which are attached to the Order.
          </p>
          <h3 className="text-[1.5rem] pb-4 font-blancotrialbold leading-[2.5rem] text-left">
            Severability
          </h3>
          <p className="text-base leading-[1.7rem] text-left text-grey pb-6">
            In the event any one or more of the provisions of this Agreement
            and/or Order shall be held to be invalid, illegal or unenforceable,
            the remaining provisions of this Agreement and/or Order shall be
            unimpaired and the Agreement and/or Order shall not be void for this
            reason alone. Such invalid, illegal or unenforceable provision shall
            be replaced by a mutually acceptable valid, legal and enforceable
            provision, which comes closest to the intention of the parties
            underlying the invalid, illegal or unenforceable provision.
          </p>
        </div>
      </div>
    </section>
  )
}
export const Head = ({ location }) => {
  const { siteUrl } = useSiteMetadata()
  return (
    <Seo
      title="Terms and Conditions"
      canonical={`${siteUrl}${location.pathname}`}
      description="You explicitly and implicitly agree to be bound by the terms and conditions of this agreement each time you use Techuz. This Agreement governs both your legal rights with regards to our business well as your use of our website, techuz.com."
    />
  )
}

export default Terms
